function Tags () {
  const elements = {}
  const promotionalTags = window.location.pathname.includes('promotional_tags')

  const initializeSelectors = () => {
    elements.displayTabs = document.querySelectorAll('.tags_component')
    elements.rankTags = document.querySelector('#rank_tags')
    elements.componentLocationContainer = document.querySelector('#component-location-container')
    elements.componentLocation = document.querySelector('#component_location')
    elements.ranksServiceTypeContainer = document.querySelector('#tags_service_type_container')
    elements.selectedServiceType = document.querySelector('#tags_service_type')
    elements.associatedTags = document.querySelector('#tag_ids')
    elements.submit = document.querySelector('#submit_tag_ranks')
    elements.pageTitle = document.querySelector('#tags_title')
    elements.submitTitle = document.querySelector('#submit_section_title')
    elements.enTitle = document.querySelector('#english_title')
    elements.arTitle = document.querySelector('#arabic_title')
    elements.location = document.querySelector('#location')
    elements.serviceTypes = document.querySelector('#service_types')
    elements.promotionTypes = document.querySelector('#promotion_types')
    elements.functionalType = document.querySelector('#functional_type')
    elements.days = document.querySelector('#days')
  }

  const initializeListeners = () => {
    $(elements.rankTags).on('click', openTagsRankModal)
    $(elements.submitTitle).on('click', validateSectionTitle)
    $(elements.location).on('change', toggleServiceTypes)
    $(elements.componentLocation).on('change', toggleRanksServiceType)
    $(elements.days).on('change', toggleCustomDaysFields)
    $(elements.functionalType).on('change', toggleCustomDaysFields)
  }

  function toggleRanksComponentLocation () {
    if (promotionalTags) {
      $(elements.componentLocationContainer).removeClass('hide')
    }
  }

  function toggleRanksServiceType () {
    if ($(elements.componentLocation).val() === 'home_screen_tags') {
      $(elements.ranksServiceTypeContainer).addClass('hide')
      elements.selectedServiceType.value = ''
      elements.selectedServiceType.required = false
    } else {
      $(elements.ranksServiceTypeContainer).removeClass('hide')
      elements.selectedServiceType.required = true
    }
  }

  function toggleServiceTypes () {
    if ($(elements.location).val() === '') {
      $('#service_types_container').addClass('hide')
    } else {
      const homeScreenOnly = $(elements.location).val() === 'home_screen_only'
      $('#service_types_container').toggleClass('hide', homeScreenOnly)
      $(elements.serviceTypes).attr('required', !homeScreenOnly)
      if (homeScreenOnly) $(elements.serviceTypes).val([])
    }
  }

  function validateSectionTitle (e) {
    const enTitleText = elements.enTitle.value
    const arTitleText = elements.arTitle.value

    const message = `Are you sure you want to change title to ${enTitleText} and ${arTitleText}?`

    const confirmed = confirm(message)

    if (!confirmed) {
      e.preventDefault()
    }
  }

  function ShowTagTypeGroup () {
    $('#tag_tag_type').change(function () {
      if ($(this).val() === 'promotional') {
        $('.promotional-group').removeClass('hide')
        $('.functional-group').addClass('hide')
        $('#functional_type').attr('required', false)
      } else if ($(this).val() === 'functional') {
        $('.promotional-group').addClass('hide')
        $('.functional-group').removeClass('hide')
        $('#functional_type').attr('required', true)
        toggleFunctionalTypeFields()
      } else {
        $('#functional_type').attr('required', false)
        $('.promotional-group').addClass('hide')
        $('.functional-group').addClass('hide')
      }
    })
  }

  function ShowHideFunctionalFields () {
    $('#functional_type').change(function () {
      toggleFunctionalTypeFields()
    })
  }

  function toggleFunctionalTypeFields () {
    if ($('#functional_type').val() === 'rating') {
      $('.rating').removeClass('hide')
      $('#rating').attr('required', true)
      $('#linked_store_ids').val('[]')
      $('#store_ids').val('[]')
      $('.stores_custom_selection').addClass('hide')
      $('.new_stores').addClass('hide')
      $('#custom_days_input').val('')
      $('.custom-days').addClass('hide')
    } else if ($('#functional_type').val() === 'group_stores') {
      $('#rating').val('').attr('required', false)
      $('.rating').addClass('hide')
      $('.stores_custom_selection').removeClass('hide')
      $('.new_stores').addClass('hide')
      $('#custom_days_input').val('')
      $('.custom-days').addClass('hide')
    } else if ($('#functional_type').val() === 'new_stores') {
      $('#rating').val('').attr('required', false)
      $('.rating').addClass('hide')
      $('.stores_custom_selection').addClass('hide')
      $('#linked_store_ids').val('[]')
      $('#store_ids').val('[]')
      $('.new_stores').removeClass('hide')
    } else {
      $('.rating').addClass('hide')
      $('#linked_store_ids').val('[]')
      $('#store_ids').val('[]')
      $('.stores_custom_selection').addClass('hide')
      $('.new_stores').addClass('hide')
      $('#custom_days_input').val('')
      $('.custom-days').addClass('hide')
    }
  }

  function toggleCustomDaysFields () {
    if ($('#days').val() === 'Custom' && $('#functional_type').val() === 'new_stores') {
      $('.custom-days').removeClass('hide')
    } else {
      $('.custom-days').addClass('hide')
      $('#custom_days_input').val($('#days').val())
    }
  }

  function AddServicesToTemplate () {
    $('#add_stores_to_tag').on('click', function (event) {
      if ($('#tag_service_type_id').val() === '') {
        event.preventDefault()
        alert('Select Service Type first')
      } else {
        $(this).attr('disabled', true)
        $(this).html('Please wait...')

        $.ajax({
          type: 'POST',
          dataType: 'script',
          url: '/tags/associate_stores',
          data: {
            linked_shop_ids: $('#store_ids').val(),
            service_type_id: $('#tag_service_type_id').val(),
            service_types_ids: $(elements.serviceTypes).val()
          }
        })
      }
    })
  }

  const ValidateFormFields = () => {
    $('#tags_submit_btn').on('click', function (event) {
      if (
        $('#functional_type').val() === 'group_stores' &&
        $('#store_ids').val() === '[]'
      ) {
        event.preventDefault()
        alert('Please select atleast one shop')
      }
    })
  }

  const initOneFieldPromotion = (container, toggler) => {
    toggler.change(function () {
      const isGeneralDiscount = $(this).val() && $(this).val().includes('general_discount')
      container.toggleClass('hide', !isGeneralDiscount)

      if (!isGeneralDiscount) {
        container.find(".form-group input[type='number']").val('')
      }
    })

    const toggleAddBtn = () => {
      const maxItems = 10
      const isDisabled = container.find('.form-group').length >= maxItems
      const addBtn = container.find('.add-btn')
      addBtn.prop('disabled', isDisabled)
      if (isDisabled) {
        addBtn.attr('title', `Cant add more than ${maxItems} items`)
      } else {
        addBtn.attr('title', '')
      }
    }

    toggleAddBtn()

    container.on('click', '.add-btn', function () {
      const template = $(this).closest('.form-group').clone()
      const input = template.find('input')
      input.val('')
      const id = input
        .attr('id')
        .replace(/\d+$/, container.find('input').length + 1)
      input.attr('id', id)
      template.appendTo(container)
      toggleAddBtn()
    })

    container.on('click', '.remove-btn', function () {
      $(this).closest('.form-group').remove()
      toggleAddBtn()
    })
  }

  const initializeModalSelectors = () => {
    elements.sortableCategories = document.querySelector('.sortable-list')
  }

  const openTagsRankModal = (event) => {
    if ((elements.selectedServiceType.value === '' &&
        !promotionalTags) ||
        (elements.componentLocation.value === 'listing_screen_tags' &&
        elements.selectedServiceType.value === '')) {
      event.preventDefault()
      alert('Please select service type first')
    } else if (elements.componentLocation.value === '' &&
            elements.selectedServiceType.value === '') {
      event.preventDefault()
      alert('Please select component location')
    } else {
      $.ajax({
        type: 'POST',
        dataType: 'script',
        url: '/tags/associate_ranks_modal',
        data: {
          service_type_id: elements.selectedServiceType.value,
          promotional_tags: promotionalTags
        },
        success: () => {
          initializeModalSelectors()
          initializeSortableTagTable()
        }
      })
    }
  }

  const initializeSortableTagTable = () => {
    $('.sortable-list')
      .sortable({
        connectWith: '.sortable-list',
        placeholder: 'sortable-placeholder',
        update: (event, ui) => {
          const childrens = event.target.querySelectorAll('.sortable-item')
          let i = 1
          const sortedItems = [...childrens].map((el) => {
            const lastColumn = el.querySelector('td:last-child')
            lastColumn.innerHTML = i++
            return Number(el.dataset.recordId)
          })
          elements.associatedTags.value = JSON.stringify(sortedItems)
        }
      })
      .disableSelection()
  }

  const loadDropDown = () => {
    $(elements.serviceTypes).multiselect({
      includeSelectAllOption: true,
      enableFiltering: true,
      maxHeight: 250,
      buttonWidth: '100%'
    })

    $(elements.promotionTypes).multiselect({
      includeSelectAllOption: true,
      enableFiltering: true,
      maxHeight: 250,
      buttonWidth: '100%'
    })
  }

  if (window.location.pathname.includes('tags')) {
    initializeSelectors()
    initializeListeners()
    initOneFieldPromotion($('#discount-promotion'), $('#promotion_types'))
    loadDropDown()
    toggleServiceTypes()
    AddServicesToTemplate()
    toggleFunctionalTypeFields()
    ShowTagTypeGroup()
    ShowHideFunctionalFields()
    ValidateFormFields()
    toggleCustomDaysFields()
    toggleRanksComponentLocation()
  }
}

export default Tags
